export const standardResidues: Set<string> = new Set([
  "ABU",
  "ACD",
  "ALA",
  "ALB",
  "ALI",
  "ARG",
  "AR0",
  "ASN",
  "ASP",
  "ASX",
  "BAS",
  "CYS",
  "CYH",
  "CYX",
  "CSS",
  "CSH",
  "GLN",
  "GLU",
  "GLX",
  "GLY",
  "HIS",
  "HIE",
  "HID",
  "HIP",
  "HYP",
  "ILE",
  "ILU",
  "LEU",
  "LYS",
  "MET",
  "PCA",
  "PGA",
  "PHE",
  "PR0",
  "PRO",
  "PRZ",
  "SER",
  "THR",
  "TRP",
  "TYR",
  "VAL",
  "A",
  "1MA",
  "C",
  "5MC",
  "OMC",
  "G",
  "1MG",
  "2MG",
  "M2G",
  "7MG",
  "OMG",
  "YG",
  "I",
  "T",
  "U",
  "+U",
  "H2U",
  "5MU",
  "PSU",
  "ACE",
  "F0R",
  "H2O",
  "HOH",
  "WAT",
]);
